.homePage {
  height: 100vh;
  min-height: 780px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.home-contact-btn {
  margin-top: 60px;
}

#external-company {
  margin-left: 5px;
  text-decoration: none;
  color: black;
}

.darkMode #external-company {
  color: white;
}

#external-company:hover {
  color: blue;
  text-decoration: underline;
}
