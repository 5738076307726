.aboutPage {
  height: 100vh;
  width: 100vw;
  min-height: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 175px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
}

.content {
  max-width: 500px;
  text-align: left;
  margin-left: 5px;
}

.Voniel {
  height: 20pc;
  width: 20pc;
  border-radius: 5px;
}

.footer {
  /* bottom: 0; */
  top: 120px;
  width: 100%;
  height: 2.5rem;
  width: 100vw;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.footer-icon {
  margin-left: 20px;
}

.footer-link {
  height: 90px;
}

#Voniel-Brown {
  font-size: 2.1rem;
  text-align: left;
  margin-left: 0.5px;
}

.fa-icon {
  font-size: 30px;
}

.linked-in {
  color: #0077b5;
}

.external-links {
  gap: 20px;
  color: black;
  display: flex;
  text-decoration: none;
}

.github {
  color: black;
}

.resume {
  color: #000000;
}

.darkMode .resume {
  color: #fafafa;
}

.footer-icon .toolTipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  left: 45%;
  padding-left: 8px;

  position: absolute;
  z-index: 1;
}

.footer-icon:hover .toolTipText {
  visibility: visible;
}

a:focus {
  outline: none;
  box-shadow: none;
}

#about {
  margin-top: -195px;
}
