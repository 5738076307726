@import url("https://fonts.googleapis.com/css2?family=Aguafina+Script&display=swap");

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    75deg,
    rgba(170, 194, 170, 0.329),
    rgba(51, 38, 38, 0.171)
  );
}

.App,
.darkMode {
  width: 100%;
  overflow-y: hidden;
  text-align: center;
  position: absolute;
}

.App-link {
  color: #61dafb;
}

.scrollToTop {
  bottom: 90px;
  left: 90vw;
  z-index: 100;
  display: flex;
  position: sticky;
  align-items: center;
}

html body .darkMode {
  overflow-x: hidden;
  background-color: black;
}

.darkMode {
  font-family: "Open Sans", sans-serif;
  background-color: black;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  color: white;
}

.darkMode div {
  color: white;
}

.darkMode button {
  color: white;
}
.darkMode .project-card {
  background-color: black;
  border-color: white;
}

.button-theme {
  height: 40px;
  width: 80px;
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
  font-size: 15px;
  background-image: linear-gradient(
    145deg,
    rgb(46, 118, 112),
    rgb(55, 140, 133)
  );
}

button {
  border: 1px transparent;
}
.nav-scroll {
  width: 40px;
  border-radius: 50px;
}

.scrollDown {
  position: sticky;
  margin-bottom: 60px;
  border-radius: 50%;
  height: 50px;
  width: 48px;
  background-color: transparent;
}

.chevronDown {
  position: sticky;
  font-size: 40px;
  z-index: -1;
  color: rgb(55, 140, 133);
}

.page-title {
  font-size: 70px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin-top: 40px;
}

.darkMode .page-title {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* css reset */
* {
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
  font-size: 16px;
}

.App {
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

@media screen and (max-width: 768px) {
  .scrollDown {
    position: relative;
    bottom: 110px;
  }
}

button:focus {
  outline: none;
  box-shadow: none;
}
