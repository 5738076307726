.contactPage {
  min-height: 780px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.react {
  color: blue;
}

.submit {
  bottom: 0;
  width: 100px;
  height: 30px;
  font-size: 20px;
  color: aliceblue;
  border-radius: 5px;
  background: linear-gradient(145deg, #2e7670, #378c85);
}

.submit:hover {
  cursor: pointer;
}

.skills-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 90px;
  margin-bottom: 50px;
  width: 100vw;
}

.skill-icon {
  height: 90px;
  margin-left: 5px;
}

.postgres {
  height: 90px;
}

@media screen and (max-width: 768px) {
  .contactPage {
    margin-top: 30px;
    height: 1200px;
    gap: 10px;
  }

  .skills-container {
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    /* flex-direction: column; */
  }
  .github {
    margin-top: 40px;
  }
}

svg:hover {
  fill: red;
}
