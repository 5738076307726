$accent-color: #355cc9;
.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.card {
  width: 300px;
  height: 370px;
  margin: 1em;
  perspective: 1500px;
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.75, 0, 0.85, 1);
  }
}

.more {
  display: none;
  &:checked ~ .content {
    transform: rotateY(180deg);
  }
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 6px;

  .inner {
    height: 100%;
    display: grid;
    padding: 1.5em;
    transform: translateZ(80px) scale(0.94);

    .tech-used-heading {
      font-size: 25px;
      color: #355cc9;
      margin-bottom: 6px;
    }

    .tech {
      color: #355cc9;
      font-size: 15px;
    }
  }
}

.front {
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 6px;
    backface-visibility: hidden;
    background: linear-gradient(
      180deg,
      rgba(17, 39, 17, 0.39),
      rgba(46, 20, 20, 0.527)
    );
  }
  .inner {
    grid-template-rows: 5fr 1fr 1fr 2fr 1fr;
    justify-items: center;
  }

  h2 {
    grid-row: 2;
    margin-bottom: 0.3em;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: black;
    font-weight: 500;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }
}

.back {
  transform: rotateY(180deg);
  background-color: #fff;
  border: 2px solid rgb(240, 240, 240);
  .inner {
    grid-template-rows: 1fr 2fr 1fr 2fr 14fr 1fr 1fr;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 0.8em;
    justify-items: center;

    a {
      color: teal;
      text-decoration: none;
      margin-left: 90px;
    }
    .links {
      display: flex;
      flex-direction: column;
      width: 230px;
    }
  }

  .info {
    position: relative;
    display: flex;
    align-items: center;
    color: $accent-color;
    grid-row: 3;
    &:not(:first-of-type):before {
      content: "";
      position: absolute;
      left: -0.9em;
      height: 18px;
      width: 1px;
      background-color: #ccc;
    }
    span {
      font-size: 2em;
      font-weight: 700;
    }
    i {
      &:before {
        background: linear-gradient(40deg, $accent-color, rgb(67, 138, 243));

        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
      font-size: 1.2em;
    }
    .icon {
      margin-left: 0.3em;
      span {
        display: block;
        margin-top: -0.25em;
        font-size: 0.8em;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }

  .description {
    grid-row: 5;
    grid-column: 1/-1;
    font-size: 0.86em;
    border-radius: 5px;
    font-weight: 600;
    line-height: 1.4em;
    overflow: auto;
    color: $accent-color;
    padding-right: 10px;
  }

  .button {
    grid-column: 1/-1;
    justify-self: center;
  }
}

.button {
  grid-row: -1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  padding: 0 1.5em;
  height: 3em;
  line-height: 2.9em;
  min-width: 3em;
  background-color: transparent;
  border: solid 2px #fff;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  left: 50%;
  backface-visibility: hidden;
  transition: 0.3s ease-in-out;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
    text-shadow: none;
    color: $accent-color;
  }

  &.return {
    line-height: 3em;
    color: $accent-color;
    border-color: $accent-color;
    text-shadow: none;
    &:hover {
      background-color: $accent-color;
      color: #fff;
      box-shadow: none;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: lighten($accent-color, 20%);
}
::-webkit-scrollbar-thumb:hover {
  background: $accent-color;
}
