.portfolioPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  min-height: 680px;
  align-items: center;
  flex-direction: column;
}
.inner-frame {
  height: 100vh;
  width: 100vw;
  display: flex;
  min-height: 680px;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 180px;
  position: absolute;
}
.page-title {
  margin-bottom: 90px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 800px) {
  .inner-frame {
    overflow-y: scroll;
    width: 95vw;
  }

  .page-title {
    margin-bottom: 10px;
  }

  .darkMode .inner-frame::-webkit-scrollbar-track {
    background-color: black;
  }

  /* ::-webkit-scrollbar-track-piece {
    color: brown;
  } */

  .portfolioPage::-webkit-scrollbar {
    display: none;
  }
  .portfolioPage .flex {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) {
  .portfolioPage .flex {
    flex-wrap: wrap;
  }
  .inner-frame {
    margin-bottom: 0px;
  }
}

.project-img {
  height: 120px;
  width: 15rem;
}

#card-title {
  margin-top: -85px;
  color: white;
  font-size: 30px;
  text-shadow: 2px 2px black;
  font-weight: bolder;
}

.award {
  font-size: 44px;
  color: gold;
}

#awardToolTipText {
  left: 180px;
  font-size: 15px;
}
