.backdrop {
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 100;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
}
.toggle-button {
  padding: 0;
  width: 36px;
  height: 24px;
  border: none;
  margin-left: 40%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  background-color: transparent;
}

.sidebar-toggle-button {
  top: 0;
  left: 10px;
  z-index: 900;
  margin-top: 8px;
  position: fixed;
}

.toggle-button:focus {
  outline: none;
}
.toggler-line {
  width: 100%;
  height: 2px;
  background-color: black;
}

.darkMode .toggler-line {
  background-color: white;
}

.side-drawer {
  top: 0;
  left: 0;
  gap: 30px;
  width: 20%;
  height: 100%;
  z-index: 200;
  position: fixed;
  max-width: 400px;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
  transform: translateX(-200%);
  transition: transform 0.9s ease-out;
}

.side-drawer .open {
  z-index: 600;
  transform: translateX(0);
}
.open {
  /* top: 0; */
  left: 0;
  width: 10%;
  height: 100%;
  z-index: 200;
  display: flex;
  font-size: 30px;
  margin-top: 40px;
  position: fixed;
  flex-direction: column;
  background-color: transparent;
  transition: transform 0.3s ease-out;
}

@media (orientation: landscape) {
  /* .navbar {
    transform: translateX(-700%);
  } */
  .navigation-items {
    display: none;
  }
  .sidebar-toggle-button {
    display: initial;
  }
  .open .navigation-items {
    display: initial;
  }
}
